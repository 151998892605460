import Layout from "antd/lib/layout";
import React, { FC } from "react";

const { Content } = Layout;

const CommonLayout: FC = ({ children }) => (
  <Layout>
    <Content>{children}</Content>
  </Layout>
);

export default CommonLayout;
