import { Button, Result } from "antd";
import CommonLayout from "components/layouts/common";
import React, { ReactElement } from "react";
import { Helmet } from "react-helmet";
import routes from "routes";
import { useNavigate } from "utils";

export default (): ReactElement => {
  const navigateToHome = useNavigate(routes.home);

  return (
    <CommonLayout>
      <Helmet title="Страница не найдена" />
      <Result
        status="404"
        title="Страница не найдена"
        subTitle="Страница устрарела, была удалена или не существует совсем"
        extra={
          <Button icon="arrow-left" onClick={navigateToHome}>
            Вернуться на домашнюю страницу
          </Button>
        }
      />
    </CommonLayout>
  );
};
